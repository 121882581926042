/**
 * @generated SignedSource<<aff21615fcb341f005192096a4422e51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddressInputGooglePlacesQuery$variables = {
  isToken: boolean;
  placeId: string;
  placeTokenId: string;
  sessionToken: string;
};
export type AddressInputGooglePlacesQuery$data = {
  readonly placeDetailsByPlaceId?: {
    readonly mapUrl: string;
  } | null | undefined;
  readonly placeDetailsByPlaceTokenId?: {
    readonly mapUrl: string;
  } | null | undefined;
};
export type AddressInputGooglePlacesQuery = {
  response: AddressInputGooglePlacesQuery$data;
  variables: AddressInputGooglePlacesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isToken"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placeTokenId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionToken"
},
v4 = {
  "kind": "Variable",
  "name": "sessionToken",
  "variableName": "sessionToken"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mapUrl",
    "storageKey": null
  }
],
v6 = [
  {
    "condition": "isToken",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "placeId",
            "variableName": "placeId"
          },
          (v4/*: any*/)
        ],
        "concreteType": "PlaceDetailsResult",
        "kind": "LinkedField",
        "name": "placeDetailsByPlaceId",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ]
  },
  {
    "condition": "isToken",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "placeTokenId",
            "variableName": "placeTokenId"
          },
          (v4/*: any*/)
        ],
        "concreteType": "PlaceDetailsResult",
        "kind": "LinkedField",
        "name": "placeDetailsByPlaceTokenId",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressInputGooglePlacesQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddressInputGooglePlacesQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "351f25937ff4e562be322d30de744bee",
    "id": null,
    "metadata": {},
    "name": "AddressInputGooglePlacesQuery",
    "operationKind": "query",
    "text": "query AddressInputGooglePlacesQuery(\n  $placeId: String!\n  $placeTokenId: ID!\n  $sessionToken: String!\n  $isToken: Boolean!\n) {\n  placeDetailsByPlaceId(placeId: $placeId, sessionToken: $sessionToken) @skip(if: $isToken) {\n    mapUrl\n  }\n  placeDetailsByPlaceTokenId(placeTokenId: $placeTokenId, sessionToken: $sessionToken) @include(if: $isToken) {\n    mapUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3ec91560fc41528281d5981d92c4151";

export default node;
