import { PropsWithChildren, ReactNode, useCallback } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Divider,
  Icon,
  PaletteColor,
  Paper,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const formSectionContent_flexLayout: SxProps<Theme> = () => ({
  display: 'flex',
  flexDirection: 'column',
});
export const formSectionContent_gridLayout: SxProps<Theme> = (theme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('xl')]: { gridTemplateColumns: '1fr 1fr 1fr' },
  [theme.breakpoints.down('lg')]: { gridTemplateColumns: '1fr 1fr' },
});
export const formSectionContent_gridWideLayout: SxProps<Theme> = () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
});

export function FormSectionContent({ sx, children }: PropsWithChildren<{ sx?: SxProps<Theme> }>) {
  return (
    <Paper
      elevation={0}
      sx={(
        [
          formSectionContent_flexLayout,
          {
            '--form-section__bleed': '1rem',
            '--form-section__gap': '0.5rem',
            gap: 'var(--form-section__gap)',
            p: 'var(--form-section__bleed)',
            pt: 'calc(var(--form-section__bleed) + 0.25rem)',
          },
          sx ?? null,
        ] satisfies SxProps<Theme>[]
      ).flat()}>
      {children}
    </Paper>
  );
}

/**
 * Intended to be used in mobile to display section information and to navigate to a form section page
 */
export function FormSectionCompact({
  label,
  onClick,
  icon,
  details,
  disabled,
  rightAdditional,
  sx,
}: {
  label: ReactNode;
  onClick: () => void;
  icon: ReactNode;
  details?: ReactNode;
  rightAdditional?: ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}) {
  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick();
  }, [disabled, onClick]);

  return (
    <Paper
      elevation={0}
      onClick={handleClick}
      sx={(
        [
          (theme) => ({
            display: 'flex',
            alignItems: 'center',
            p: '1rem',
            gap: '1rem',
            borderRadius: 0,
            cursor: 'pointer',
            ...(disabled
              ? {
                  opacity: theme.palette.action.disabledOpacity,
                }
              : {}),
          }),
          sx ?? null,
        ] satisfies SxProps<Theme>[]
      ).flat()}>
      <Icon>{icon}</Icon>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: 0 }}>
        {label}
        {details}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {rightAdditional}
        <Icon>
          <ChevronRightIcon />
        </Icon>
      </Box>
    </Paper>
  );
}

export function FormSectionContent_SubLayout({ sx, children }: PropsWithChildren<{ sx?: SxProps<Theme> }>) {
  return <Box sx={([{ gap: 'var(--form-section__gap)' }, sx ?? null] satisfies SxProps<Theme>[]).flat()}>{children}</Box>;
}

export function FormSectionHeader({ label, actions, adornments }: { label?: ReactNode; actions?: ReactNode; adornments?: ReactNode }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        gridColumn: '1/-1',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 'var(--form-section__gap, 0.5rem)',
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        '.form-section__adornment': { flexGrow: 0 },
        '.form-section__adornment:last-of-type': { flexGrow: 1 },
      }}>
      {label}
      {adornments}
      {actions}
    </Box>
  );
}

export function FormSectionHeader_Title({ label }: { label: string }) {
  return (
    <span className='form-section__adornment'>
      <Typography variant='subtitle1' component='h3' sx={{ mt: '1rem', mb: '0.25rem' }}>
        {label}
      </Typography>
    </span>
  );
}

export function FormSectionHeader_Subtitle({ label }: { label: string }) {
  const theme = useTheme();
  const sectionTitleInHeader = theme.breakpoints.down('sm');

  return (
    <span className='form-section__adornment'>
      <Typography
        variant='overline'
        component={sectionTitleInHeader ? 'h3' : 'h4'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            mt: '0.5rem',
          },
        }}>
        {label}
      </Typography>
    </span>
  );
}

export function FormSectionHeader_AdornmentProgress() {
  return (
    <Box component='span' className='form-section__adornment' sx={{ lineHeight: 1 }}>
      <CircularProgress size={18} />
    </Box>
  );
}

export function FormSectionHeader_AdornmentInlineError({ error }: { error: string }) {
  return (
    <Typography
      component='span'
      variant='caption'
      className='form-section__adornment'
      sx={(theme) => ({ color: theme.palette.error.main, mt: '1rem', mb: '0.25rem' })}>
      {error}
    </Typography>
  );
}

export function FormSectionHeader_AdornmentTooltip({ title, color }: { title: string; color?: (theme: Theme) => PaletteColor }) {
  return (
    <Tooltip title={title} placement='top'>
      <span className='form-section__adornment'>
        <WarningIcon sx={(theme) => ({ position: 'absolute', color: color?.(theme).main ?? theme.palette.error.main, mt: '1rem' })} />
        <WarningAmberIcon sx={(theme) => ({ color: color?.(theme).contrastText ?? theme.palette.error.contrastText, mt: '1rem' })} />
      </span>
    </Tooltip>
  );
}

export function FormSectionHeader_ActionButton(props: Omit<ButtonProps, 'variant' | 'size'>) {
  return <Button variant='text' size='small' color='secondary' {...props}></Button>;
}

export function FormSectionDivider({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Divider
      sx={(
        [{ mt: '0.75rem', mx: 'calc(0px - var(--form-section__bleed))', gridColumn: '1/-1' }, sx ?? null] satisfies SxProps<Theme>[]
      ).flat()}
    />
  );
}
