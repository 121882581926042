import { ElementType, forwardRef, HTMLAttributes, ReactElement, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { ChipTypeMap } from '@mui/material/Chip';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';
import { DataID, fetchQuery, useRelayEnvironment } from 'react-relay';
import { nanoid } from 'nanoid';
import graphql from 'babel-plugin-relay/macro';
import { _throw } from '../utils/_throw';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Svg } from './Svg';
import { AddressInput, AddressInputProps } from './AddressInput';
import { AddressInputGooglePlacesQuery } from './__generated__/AddressInputGooglePlacesQuery.graphql';

export interface GoogleMapAddressInputProps<
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AddressInputProps<DisableClearable, ChipComponent>,
    'freeSolo' | 'autoSelect' | 'options' | 'renderInput' | 'sessionToken' | 'getOptionKey'
  > {
  placeId: string | null;
  placeTokenId: string | null;
  error?: boolean;
}

export const AddressInputGooglePlaces = forwardRef(function AddressInputGooglePlaces<
  DisableClearable extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    onChange,
    value: placeAddress,
    placeId,
    placeTokenId,
    error,
    textFieldProps,
    ...autoCompleteProps
  }: GoogleMapAddressInputProps<DisableClearable, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const [, setSubscription] = useCancellableSubscription();
  const relay = useRelayEnvironment();
  const [sessionToken, setSessionToken] = useState<string>(nanoid());
  const [isLoading, setIsLoading] = useState(false);
  const mapUrlRef = useRef<string | null>(null);
  const [selectedPlaceId, setSelectedPlaceId] = useState(placeId);
  const [selectedAddress, setSelectedAddress] = useState(placeAddress);

  useEffect(() => {
    setSelectedPlaceId(placeId);
  }, [placeId]);

  const getPlaceDetails = useCallback(
    (newPlaceId: string, ptId: DataID | null, sToken: string, address: string, onComplete?: (url: string) => void) => {
      setSubscription(
        fetchQuery<AddressInputGooglePlacesQuery>(
          relay,
          graphql`
            query AddressInputGooglePlacesQuery($placeId: String!, $placeTokenId: ID!, $sessionToken: String!, $isToken: Boolean!) {
              placeDetailsByPlaceId(placeId: $placeId, sessionToken: $sessionToken) @skip(if: $isToken) {
                mapUrl
              }
              placeDetailsByPlaceTokenId(placeTokenId: $placeTokenId, sessionToken: $sessionToken) @include(if: $isToken) {
                mapUrl
              }
            }
          `,
          { placeId: newPlaceId, placeTokenId: ptId ?? '', sessionToken: sToken, isToken: !!ptId },
        ).subscribe({
          start: () => {
            setIsLoading(true);
          },
          next: (result) => {
            mapUrlRef.current = (result.placeDetailsByPlaceId ?? result.placeDetailsByPlaceTokenId)?.mapUrl ?? null;
            setSessionToken(nanoid());
          },
          complete: () => {
            setIsLoading(false);
            onComplete?.(mapUrlRef.current ?? _throw(new Error('InvalidOperationException: Map url not found')));
          },
          error: () => {
            setIsLoading(false);
          },
          unsubscribe: () => {
            setIsLoading(false);
          },
        }),
      );
    },
    [relay, setSubscription],
  );

  const handleChange = useCallback<AddressInputProps<DisableClearable, ChipComponent>['onChange']>(
    (value, event) => {
      if (value && typeof value !== 'string') {
        setSelectedPlaceId(value.placeId);
        setSelectedAddress(value);
        getPlaceDetails(value.placeId, null, sessionToken, value.label ?? '');
      }

      onChange(value, event);
    },
    [getPlaceDetails, onChange, sessionToken],
  );

  const handleOnMapsIconClick = useCallback(() => {
    if (!mapUrlRef.current) {
      if (selectedPlaceId) {
        getPlaceDetails(
          selectedPlaceId,
          placeTokenId,
          sessionToken,
          typeof selectedAddress !== 'string' ? (selectedAddress?.label ?? '') : selectedAddress,
          openNewTab,
        );
      }
    } else {
      openNewTab(mapUrlRef.current);
    }
  }, [getPlaceDetails, placeTokenId, selectedAddress, selectedPlaceId, sessionToken]);

  const handleQuery = useCallback<NonNullable<AddressInputProps<DisableClearable, ChipComponent>['onQuery']>>(
    (searchTerm, event) => {
      onChange(searchTerm, event);
    },
    [onChange],
  );

  return (
    <AddressInput
      {...autoCompleteProps}
      ref={ref}
      value={placeAddress}
      onChange={handleChange}
      onQuery={handleQuery}
      onFocus={() => setSessionToken(nanoid())}
      ListboxComponent={GoogleMapListBoxComponent}
      sessionToken={sessionToken}
      error={error}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          ...p,
          InputProps: {
            ...(p?.InputProps ?? params.InputProps),
            endAdornment: (
              <>
                {selectedPlaceId && <GoogleMapsAdornment isLoading={isLoading} onClick={handleOnMapsIconClick} />}
                {p?.InputProps?.endAdornment ?? params.InputProps.endAdornment}
              </>
            ),
          },
        };
      }}
    />
  );
}) as <DisableClearable extends boolean | undefined, ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']>(
  props: GoogleMapAddressInputProps<DisableClearable, ChipComponent> & { ref?: Ref<HTMLInputElement> },
) => ReactElement;

function openNewTab(url: string) {
  window.open(url, '_blank', 'noopener noreferrer');
}

function GoogleMapsAdornment({ isLoading, onClick: handleClick }: { isLoading: boolean; onClick: () => void }) {
  return (
    <InputAdornment position='end'>
      {isLoading && <CircularProgress color='inherit' size={20} />}
      {!isLoading && (
        <IconButton size='small' sx={{ py: 0 }} onClick={handleClick}>
          <GoogleMapsPlaceIconSvg />
        </IconButton>
      )}
    </InputAdornment>
  );
}

const GoogleMapListBoxComponent = forwardRef(function GoogleMapListBoxComponent(
  props: HTMLAttributes<HTMLUListElement>,
  ref: Ref<HTMLUListElement>,
) {
  const { children, ...rest } = props;
  return (
    <ul {...rest} ref={ref}>
      {children}
      <li style={{ marginTop: '0.5rem' }}>
        <a href='https://cloud.google.com/maps-platform/terms/' target='_blank' rel='noopener noreferrer' style={{ marginLeft: '1rem' }}>
          <img src='/imgs/google_on_white_hdpi.png' alt='google' style={{ height: '1rem' }} />
        </a>
      </li>
    </ul>
  );
});

function GoogleMapsPlaceIconSvg() {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width='13' height='23' viewBox='0 0 25 36' fill='none'>
      <g clipPath='url(#clip0_5868_17549)'>
        <path
          d='M16.3056 0.598639C15.1138 0.217687 13.8137 0 12.4865 0C8.66744 0 5.22757 1.7415 2.92529 4.4898L8.82995 9.46939L16.3056 0.598639Z'
          fill='#1A73E8'
        />
        <path
          d='M2.92524 4.49023C1.11051 6.66711 0 9.49704 0 12.5447C0 14.912 0.460455 16.8168 1.24594 18.5311L8.8299 9.46983L2.92524 4.49023Z'
          fill='#EA4335'
        />
        <path
          d='M12.5137 7.7551C15.1681 7.7551 17.3079 9.90476 17.3079 12.5714C17.3079 13.7415 16.8745 14.8299 16.1703 15.6735C16.1703 15.6735 19.9352 11.1565 23.6188 6.7755C22.102 3.83673 19.4747 1.60544 16.3057 0.598633L8.83008 9.46938C9.7239 8.43537 11.024 7.7551 12.5137 7.7551Z'
          fill='#4285F4'
        />
        <path
          d='M12.5137 17.3609C9.85931 17.3609 7.71955 15.2112 7.71955 12.5446C7.71955 11.3745 8.12583 10.2861 8.83006 9.46973L1.24609 18.531C2.5462 21.4153 4.71305 23.7554 6.93407 26.667L16.1703 15.6466C15.2764 16.7078 13.9763 17.3609 12.5137 17.3609Z'
          fill='#FBBC04'
        />
        <path
          d='M16.0078 29.7142C20.1789 23.1563 25.0273 20.1904 25.0273 12.5713C25.0273 10.4761 24.5126 8.51689 23.6188 6.77539L6.93408 26.6665C7.63831 27.5917 8.36962 28.6529 9.07384 29.7414C11.6199 33.687 10.9157 36.0271 12.5408 36.0271C14.1659 36.0271 13.4617 33.6597 16.0078 29.7142Z'
          fill='#34A853'
        />
      </g>
      <defs>
        <clipPath id='clip0_5868_17549'>
          <rect width='25' height='36' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  );
}
