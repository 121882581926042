/**
 * @generated SignedSource<<80ac40aeb7f0a4c277171bd019fa5138>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutsSimpleLayoutFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsSimpleLayoutToolbarFragment">;
  readonly " $fragmentType": "LayoutsSimpleLayoutFragment";
};
export type LayoutsSimpleLayoutFragment$key = {
  readonly " $data"?: LayoutsSimpleLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsSimpleLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutsSimpleLayoutFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsSimpleLayoutToolbarFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a13dbf518b6014ebf28555184f36080b";

export default node;
