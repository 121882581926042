import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DateTime } from 'luxon';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { ChangeEvent, ReactNode, Suspense, useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Chip, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Place } from '../../common/components/AddressInput';
import { AddressInputGooglePlaces } from '../../common/components/AddressInputGooglePlaces';
import { exclude } from '../../common/utils/arrayUtils';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementsDialog } from '../../common/dialogs/RequirementsDialog';
import {
  ForwardWorksiteRequirementAutocompleteProps,
  WorksiteRequirementAutocomplete,
} from '../../common/components/WorksiteRequirementAutocomplete';
import { MaskInput } from '../../common/components/MaskInput';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { RoundedDateTimePicker } from '../../common/components/FormDateTimePicker';
import { dateTimeFormat, parseDateTime } from '../../common/utils/dateTimeUtils';
import {
  ProjectBaseFields_WorksiteOverrides_LocationFragment$data,
  ProjectBaseFields_WorksiteOverrides_LocationFragment$key,
} from './__generated__/ProjectBaseFields_WorksiteOverrides_LocationFragment.graphql';
import {
  ProjectBaseFields_WorksiteOverrides_NameFragment$data,
  ProjectBaseFields_WorksiteOverrides_NameFragment$key,
} from './__generated__/ProjectBaseFields_WorksiteOverrides_NameFragment.graphql';
import { ProjectBaseFields_AssignedWorksiteFragment$key } from './__generated__/ProjectBaseFields_AssignedWorksiteFragment.graphql';
import { ProjectBaseFields_DurationHoursPerDayFragment$key } from './__generated__/ProjectBaseFields_DurationHoursPerDayFragment.graphql';
import { ProjectBaseFields_DurationLengthInDaysFragment$key } from './__generated__/ProjectBaseFields_DurationLengthInDaysFragment.graphql';
import {
  ProjectBaseFields_ArrivalDateFragment$data,
  ProjectBaseFields_ArrivalDateFragment$key,
} from './__generated__/ProjectBaseFields_ArrivalDateFragment.graphql';
import { ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment.graphql';
import { ProjectBaseFields_WorksiteOverrides_ContactFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverrides_ContactFragment.graphql';
import { ProjectBaseFields_RequirementsFragment$key } from './__generated__/ProjectBaseFields_RequirementsFragment.graphql';
import { NumberInput } from '../../common/components/NumberInput';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useFieldAssignedClient, useFieldClientOverridesIsDirectSales, useFieldClientOverridesLocation } from './ClientBaseFields';
import { ProjectBaseFields_CopyClientAddressButtonFragment$key } from './__generated__/ProjectBaseFields_CopyClientAddressButtonFragment.graphql';
import { ProjectBaseFields_WorksiteLocationInput_ClientFragment$key } from './__generated__/ProjectBaseFields_WorksiteLocationInput_ClientFragment.graphql';
import { ProjectBaseFields_WorksiteOverrides_ClientLocationFragment$key } from './__generated__/ProjectBaseFields_WorksiteOverrides_ClientLocationFragment.graphql';
import { ProjectBaseFields_QueriedCopyClientAddressButtonQuery } from './__generated__/ProjectBaseFields_QueriedCopyClientAddressButtonQuery.graphql';
import { FallbackErrorBoundary } from '../../common/components/FallbackErrorBoundary';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { ProjectBaseFields_DurationLengthInDays_SuggestionsQuery } from './__generated__/ProjectBaseFields_DurationLengthInDays_SuggestionsQuery.graphql';
import { useSuggestions } from '../useSuggestions';
import { ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key } from './__generated__/ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery } from './__generated__/ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery.graphql';
import { ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key } from './__generated__/ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment.graphql';
import { ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key } from './__generated__/ProjectBaseFields_WorksiteRequirementsSuggestionsFragment.graphql';

const fieldAssignedWorksiteKey = createFieldKey<{ id: DataID; etag: string } | null>();

export function useFieldAssignedWorksite($key: ProjectBaseFields_AssignedWorksiteFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_AssignedWorksiteFragment on ProjectInternalBase {
        assignedWorksiteId
      }
    `,
    $key,
  );

  const [assignedWorksite, setAssignedWorksite] = useField(
    jobStageBaseFormContext,
    fieldAssignedWorksiteKey,
    useMemo(() => {
      if (!$data?.assignedWorksiteId) {
        return null;
      }
      if (!$data.assignedWorksiteId) {
        throw new Error('[useFieldAssignedWorksite] assignedWorksite does not support being reset');
      }
      return { id: $data.assignedWorksiteId, etag: nanoid() };
    }, [$data]),
  );

  const assignedWorksiteIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAssignedWorksiteKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  useValidation((val) => val === null && 'error.worksiteIsRequired', [], 'transferable,submittable,upgradable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteId: v ? (v.id !== 'new' ? v.id : null) : null } }), [], 'save');

  return { assignedWorksite, setAssignedWorksite, assignedWorksiteIsDirty };
}

export function useFieldAssignedWorksiteErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAssignedWorksiteKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAssignedWorksiteKey);

  return { assignedWorksiteErrorMessage: message, assignedWorksiteErrorParams: errorParams, assignedWorksiteHasErrors: hasErrors };
}
const fieldWorksiteOverridesNameKey = createFieldKey<string>();
const fieldWorksiteOverridesNameFragment = graphql`
  fragment ProjectBaseFields_WorksiteOverrides_NameFragment on IOverridableWorksite {
    name
  }
`;
const initFieldWorksiteOverridesName = ($data: ProjectBaseFields_WorksiteOverrides_NameFragment$data | null | undefined) =>
  $data?.name ?? '';

export function useFieldWorksiteOverridesNameValue($key: ProjectBaseFields_WorksiteOverrides_NameFragment$key | null | undefined) {
  const $data = useFragment(fieldWorksiteOverridesNameFragment, $key);
  const worksiteOverridesName = useFieldValue(
    jobStageBaseFormContext,
    fieldWorksiteOverridesNameKey,
    initFieldWorksiteOverridesName($data),
  );

  return { worksiteOverridesName };
}

export function useFieldWorksiteOverridesName(
  $key: ProjectBaseFields_WorksiteOverrides_NameFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(fieldWorksiteOverridesNameFragment, $key);

  const [worksiteOverridesName, setWorksiteOverridesName] = useField(
    jobStageBaseFormContext,
    fieldWorksiteOverridesNameKey,
    initFieldWorksiteOverridesName($data),
  );

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);
  useValidation((v) => (required ? !!v : true), [required], 'transferable,submittable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { name: v } } }), [], 'save');

  const renderWorksiteOverridesName = useCallback(
    () => <WorksiteNameInput value={worksiteOverridesName} setValue={setWorksiteOverridesName} disabled={disabled} required={required} />,
    [disabled, required, setWorksiteOverridesName, worksiteOverridesName],
  );

  return { worksiteOverridesName, setWorksiteOverridesName, renderWorksiteOverridesName };
}

function WorksiteNameInput({
  value,
  setValue,
  disabled,
  required,
}: {
  value: string;
  setValue: SetValueFn<string>;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesNameKey);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  return (
    <TextField
      label={t('field.name')}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      error={hasErrors}
      required={required}
      data-label-key='field.name'
    />
  );
}

type WorksiteOverridesLocationFieldType = {
  address: string;
  placeToken: { id: string | null; placeId: string } | null;
};
const fieldWorksiteOverridesLocationKey = createFieldKey<WorksiteOverridesLocationFieldType>();
const fieldWorksiteOverridesLocationFragment = graphql`
  fragment ProjectBaseFields_WorksiteOverrides_LocationFragment on IOverridableWorksite {
    location {
      address
      placeToken {
        id
        placeId
      }
    }
  }
`;
const initFieldWorksiteOverridesLocation = ($data: ProjectBaseFields_WorksiteOverrides_LocationFragment$data | null | undefined) => ({
  address: $data?.location?.address ?? '',
  placeToken: $data?.location?.placeToken ?? null,
});

export function useFieldWorksiteOverridesLocationValue($key: ProjectBaseFields_WorksiteOverrides_LocationFragment$key | null | undefined) {
  const $data = useFragment(fieldWorksiteOverridesLocationFragment, $key);
  const worksiteOverridesLocation = useFieldValue(
    jobStageBaseFormContext,
    fieldWorksiteOverridesLocationKey,
    initFieldWorksiteOverridesLocation($data),
  );

  return { worksiteOverridesLocation };
}

export function useFieldWorksiteOverridesLocation(
  $key: ProjectBaseFields_WorksiteOverrides_LocationFragment$key | null | undefined,
  client$key: ProjectBaseFields_WorksiteOverrides_ClientLocationFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(fieldWorksiteOverridesLocationFragment, $key);
  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverrides_ClientLocationFragment on ClientInternalBase {
        ...ProjectBaseFields_WorksiteLocationInput_ClientFragment
      }
    `,
    client$key,
  );

  const [worksiteOverridesLocation, setWorksiteOverridesLocation] = useField(
    jobStageBaseFormContext,
    fieldWorksiteOverridesLocationKey,
    initFieldWorksiteOverridesLocation($data),
  );

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldWorksiteOverridesLocationKey);
  useValidation((v) => (required ? !!v.address : true), [required], 'transferable,submittable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesLocationKey);
  useMapper(
    (v) => ({
      projectBase: {
        assignedWorksiteInfo: {
          location: v
            ? {
                address: v.address,
                placeId: v.placeToken?.placeId ?? null,
              }
            : null,
        },
      },
    }),
    [],
    'save',
  );

  const renderWorksiteOverridesLocation = useCallback(
    () => (
      <WorksiteLocationInput
        value={worksiteOverridesLocation}
        setValue={setWorksiteOverridesLocation}
        disabled={disabled}
        client$key={client$data}
        required={required}
      />
    ),
    [worksiteOverridesLocation, setWorksiteOverridesLocation, disabled, client$data, required],
  );

  return { worksiteOverridesLocation, setWorksiteOverridesLocation, renderWorksiteOverridesLocation };
}

function WorksiteLocationInput({
  value,
  setValue,
  disabled,
  client$key,
  required,
}: {
  value: WorksiteOverridesLocationFieldType;
  setValue: SetValueFn<WorksiteOverridesLocationFieldType>;
  disabled: boolean;
  client$key: ProjectBaseFields_WorksiteLocationInput_ClientFragment$key | null | undefined;
  required: boolean;
}) {
  const { t } = useTranslation('worksite');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldWorksiteOverridesLocationKey);

  const handleChange = useCallback(
    (newValue: string | Place | null) => {
      setValue((prev) =>
        typeof newValue === 'string' || newValue === null
          ? { ...prev, address: newValue ?? '', placeToken: null }
          : { ...prev, address: newValue.description, placeToken: { id: null, placeId: newValue.placeId } },
      );
    },
    [setValue],
  );

  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteLocationInput_ClientFragment on ClientInternalBase {
        ...ClientBaseFields_AssignedClientFragment
        assignedClientInfo {
          ...ProjectBaseFields_CopyClientAddressButtonFragment
          ...ClientBaseFields_ClientOverrides_IsDirectSalesFragment
        }
        assignedClient {
          ...ProjectBaseFields_CopyClientAddressButtonFragment
        }
      }
    `,
    client$key,
  );
  const { assignedClient, assignedClientIsDirty } = useFieldAssignedClient(client$data);
  const { clientOverridesIsDirectSales } = useFieldClientOverridesIsDirectSales(client$data?.assignedClientInfo);

  const copyClientAddressButton = assignedClient ? (
    clientOverridesIsDirectSales ? (
      <CopyClientAddressButton client$key={client$data?.assignedClientInfo} setValue={setValue} disabled={disabled} />
    ) : assignedClientIsDirty ? (
      <FallbackErrorBoundary>
        <Suspense fallback={<CircularProgress size={20} />}>
          <ProjectBaseFields_QueriedCopyClientAddressButton clientId={assignedClient.id} setValue={setValue} disabled={disabled} />
        </Suspense>
      </FallbackErrorBoundary>
    ) : (
      <CopyClientAddressButton client$key={client$data?.assignedClient} setValue={setValue} disabled={disabled} />
    )
  ) : null;

  return (
    <AddressInputGooglePlaces
      value={value.address}
      onChange={handleChange}
      placeId={value.placeToken?.placeId ?? null}
      placeTokenId={value.placeToken?.id ?? null}
      error={hasErrors}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.address'),
        'data-label-key': 'field.address',
        required,
        inputProps: { ...params.inputProps, maxLength: 200 },
        InputProps: {
          error: hasErrors,
          ...params.InputProps,
          endAdornment: copyClientAddressButton,
        },
      })}
    />
  );
}

function ProjectBaseFields_QueriedCopyClientAddressButton({
  clientId,
  setValue,
  disabled,
}: {
  clientId: DataID;
  setValue: SetValueFn<WorksiteOverridesLocationFieldType>;
  disabled: boolean;
}) {
  const $data = useLazyLoadQuery<ProjectBaseFields_QueriedCopyClientAddressButtonQuery>(
    graphql`
      query ProjectBaseFields_QueriedCopyClientAddressButtonQuery($id: ID!) {
        assignedClient: node(id: $id) @required(action: THROW) {
          ... on Client {
            ...ProjectBaseFields_CopyClientAddressButtonFragment
          }
        }
      }
    `,
    { id: clientId },
  );

  return <CopyClientAddressButton client$key={$data.assignedClient} setValue={setValue} disabled={disabled} />;
}

function CopyClientAddressButton({
  client$key,
  setValue,
  disabled,
}: {
  client$key: ProjectBaseFields_CopyClientAddressButtonFragment$key | null | undefined;
  setValue: SetValueFn<WorksiteOverridesLocationFieldType>;
  disabled: boolean;
}) {
  const client$data = useFragment(
    graphql`
      fragment ProjectBaseFields_CopyClientAddressButtonFragment on IOverridableClient {
        location {
          address
          placeToken {
            id
            placeId
          }
        }
        ...ClientBaseFields_ClientOverrides_IsDirectSalesFragment
        ...ClientBaseFields_ClientOverrides_LocationFragment
      }
    `,
    client$key,
  );

  const { clientOverridesIsDirectSales } = useFieldClientOverridesIsDirectSales(client$data);
  const { clientOverridesLocation } = useFieldClientOverridesLocation(client$data, disabled);

  const clientLocation = clientOverridesIsDirectSales
    ? clientOverridesLocation
    : client$data?.location
      ? {
          ...client$data.location,
          placeToken: {
            id: client$data.location.placeToken?.id ?? null,
            placeId: client$data.location.placeToken?.placeId ?? '',
          },
        }
      : null;

  const handlePasteClientAddress = () => {
    if (!clientLocation) return;
    setValue(clientLocation);
  };

  return (clientOverridesIsDirectSales && !clientOverridesLocation.address) || disabled ? null : (
    <IconButton onClick={handlePasteClientAddress}>
      <ContentPasteIcon />
    </IconButton>
  );
}

type useFieldWorksiteOverridesRequirementsReturns = {
  worksiteOverridesRequirements: ForwardWorksiteRequirementAutocompleteProps<true>['value'];
  setWorksiteOverridesRequirements: SetValueFn<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
  renderWorksiteOverridesRequirements: (
    input$key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined,
    saleKind: ServiceCallKind,
  ) => ReactNode;
  renderWorksiteOverridesRequirementsNoSuggestions: () => ReactNode;
};
const fieldWorksiteOverridesRequirementsKey = createFieldKey<ForwardWorksiteRequirementAutocompleteProps<true>['value']>();
function equalsById(left: { id: DataID }, right: { id: DataID }) {
  return left.id === right.id;
}

export function useFieldWorksiteOverridesRequirements(
  $key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ProjectBaseFields_RequirementsFragment$key | null | undefined,
  disabled: boolean,
): useFieldWorksiteOverridesRequirementsReturns {
  const requirementsFragment = graphql`
    fragment ProjectBaseFields_RequirementsFragment on Requirement @relay(plural: true) {
      id
      label
    }
  `;

  const $data = useFragment(requirementsFragment, $key);
  const static$data = useFragment(requirementsFragment, static$key);

  const [worksiteOverridesRequirements, setWorksiteOverridesRequirementsInternal] = useField(
    jobStageBaseFormContext,
    fieldWorksiteOverridesRequirementsKey,
    exclude($data ?? [], static$data ?? [], equalsById),
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesRequirementsKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { requirements: v ? { ids: v.map((r) => r.id) } : null } } }), [], 'save');

  const setWorksiteOverridesRequirements = useCallback<typeof setWorksiteOverridesRequirementsInternal>(
    (newValue, dirty) => {
      if (typeof newValue === 'function') {
        setWorksiteOverridesRequirementsInternal((old) => exclude(newValue(old) ?? [], static$data ?? [], equalsById), dirty);
      } else {
        setWorksiteOverridesRequirementsInternal(exclude(newValue ?? [], static$data ?? [], equalsById), dirty);
      }
    },
    [setWorksiteOverridesRequirementsInternal, static$data],
  );

  const displayedRequirements = useMemo(
    () => [...(static$data ?? []), ...exclude(worksiteOverridesRequirements ?? [], static$data ?? [], equalsById)],
    [worksiteOverridesRequirements, static$data],
  );

  const renderWorksiteOverridesRequirements = useCallback(
    (input$key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <WorksiteRequirementsSuggested
        $key={input$key}
        value={displayedRequirements}
        setValue={setWorksiteOverridesRequirements}
        saleKind={saleKind}
        disabled={disabled}
        staticRequirements={static$data ?? []}
      />
    ),
    [disabled, displayedRequirements, setWorksiteOverridesRequirements, static$data],
  );

  const renderWorksiteOverridesRequirementsNoSuggestions = useCallback(
    () => (
      <WorksiteRequirementsInput
        value={displayedRequirements}
        setValue={setWorksiteOverridesRequirements}
        disabled={disabled}
        staticRequirements={static$data ?? []}
        suggestionPromptInput={null}
      />
    ),
    [disabled, displayedRequirements, setWorksiteOverridesRequirements, static$data],
  );

  return {
    worksiteOverridesRequirements,
    setWorksiteOverridesRequirements,
    renderWorksiteOverridesRequirements,
    renderWorksiteOverridesRequirementsNoSuggestions,
  };
}

function WorksiteRequirementsSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  staticRequirements,
}: {
  $key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined;
  value: ForwardWorksiteRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
}) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteRequirementsSuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <WorksiteRequirementsInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      staticRequirements={staticRequirements}
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function WorksiteRequirementsInput({
  value,
  setValue,
  disabled,
  staticRequirements,
  suggestionPromptInput,
}: {
  value: ForwardWorksiteRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardWorksiteRequirementAutocompleteProps<true>['value']>;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const { t } = useTranslation('worksite');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = useCallback(
    (values: ForwardWorksiteRequirementAutocompleteProps<true>['value']) => {
      // we need to strip static requirements from results since they are not really part of the value.
      setValue(values?.filter((v) => !staticRequirements.some((sr) => sr.id === v.id)));
    },
    [setValue, staticRequirements],
  );

  return (
    <>
      <WorksiteRequirementAutocomplete
        multiple
        value={value}
        onChange={handleChange}
        suggestible={!!suggestionPromptInput}
        suggestionPromptInput={suggestionPromptInput}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const isDefaultRequirement = staticRequirements.some((r) => r.id === option.id);
            const handleDelete = isDefaultRequirement ? undefined : getTagProps({ index }).onDelete;
            return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' disabled={disabled} />;
          })
        }
        getOptionDisabled={(option) => staticRequirements.some((r) => r.id === option.id)}
        textFieldProps={(params) => ({
          label: t('field.requirements', { ns: 'common' }),
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <>
                {value && value.length > 0 && (
                  <IconButton onClick={() => setDialogOpen(true)} sx={{ position: 'absolute', left: 0, top: 'calc(50% - 1.25rem)' }}>
                    <InfoIcon sx={(theme) => ({ color: theme.palette.grey.A400 })} />
                  </IconButton>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          },
        })}
        sx={value && value.length > 0 ? { '.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart': { pl: '2.5rem' } } : {}}
        disabled={disabled}
      />
      <RequirementsDialog
        requirementIds={value?.map((r) => r.id) ?? []}
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        title={t('dialog.requirementsTitle', { ns: 'worksite' })}
      />
    </>
  );
}

const fieldWorksiteOverridesContactKey = createFieldKey<string>();

export function useFieldWorksiteOverridesContact(
  $key: ProjectBaseFields_WorksiteOverrides_ContactFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverrides_ContactFragment on IOverridableWorksite {
        contact
      }
    `,
    $key,
  );

  const [worksiteOverridesContact, setWorksiteOverridesContact] = useField(
    jobStageBaseFormContext,
    fieldWorksiteOverridesContactKey,
    $data?.contact ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldWorksiteOverridesContactKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { contact: v || null } } }), [], 'save');

  const renderWorksiteOverridesContact = useCallback(
    () => <WorksiteContactInput value={worksiteOverridesContact} setValue={setWorksiteOverridesContact} disabled={disabled} />,
    [disabled, setWorksiteOverridesContact, worksiteOverridesContact],
  );

  return { worksiteOverridesContact, setWorksiteOverridesContact, renderWorksiteOverridesContact };
}

function WorksiteContactInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('worksite');
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  return <TextField label={t('field.contact')} value={value} onChange={handleChange} disabled={disabled} />;
}

const worksiteOverridesPhoneNumberFieldKey = createFieldKey<string>();

export function useFieldWorksiteOverridesPhoneNumber(
  $key: ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment on IOverridableWorksite {
        phoneNumber
      }
    `,
    $key,
  );

  const [worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber] = useField(
    jobStageBaseFormContext,
    worksiteOverridesPhoneNumberFieldKey,
    $data?.phoneNumber ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, worksiteOverridesPhoneNumberFieldKey);
  useMapper((v) => ({ projectBase: { assignedWorksiteInfo: { phoneNumber: v || null } } }), [], 'save');

  const renderWorksiteOverridesPhoneNumber = useCallback(
    () => <WorksitePhoneNumberInput value={worksiteOverridesPhoneNumber} setValue={setWorksiteOverridesPhoneNumber} disabled={disabled} />,
    [worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber, disabled],
  );

  return { worksiteOverridesPhoneNumber, setWorksiteOverridesPhoneNumber, renderWorksiteOverridesPhoneNumber };
}

function WorksitePhoneNumberInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('worksite');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  return (
    <TextField
      label={t('field.phoneNumber')}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      InputProps={{
        inputComponent: MaskInput,
        inputProps: {
          mask: '000-000-0000',
        },
      }}
    />
  );
}

const fieldArrivalDateKey = createFieldKey<DateTime<boolean> | null>();
const fieldArrivalDateFragment = graphql`
  fragment ProjectBaseFields_ArrivalDateFragment on ProjectInternalBase @argumentDefinitions(isCopy: { type: "Boolean!" }) {
    arrivalDate @skip(if: $isCopy) {
      rawValue
    }
  }
`;
const initFieldArrivalDate = ($data: ProjectBaseFields_ArrivalDateFragment$data | null | undefined) =>
  parseDateTime($data?.arrivalDate?.rawValue);

export function useFieldArrivalDateValue($key: ProjectBaseFields_ArrivalDateFragment$key | null | undefined) {
  const $data = useFragment(fieldArrivalDateFragment, $key);
  const arrivalDate = useFieldValue(jobStageBaseFormContext, fieldArrivalDateKey, initFieldArrivalDate($data));
  const arrivalDateIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldArrivalDateKey);
  return { arrivalDate, arrivalDateIsDirty };
}

export function useFieldArrivalDate(
  $key: ProjectBaseFields_ArrivalDateFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(fieldArrivalDateFragment, $key);
  const [arrivalDate, setArrivalDate] = useField(jobStageBaseFormContext, fieldArrivalDateKey, initFieldArrivalDate($data));

  const useValidationArrivalDate = useFieldValidation(jobStageBaseFormContext, fieldArrivalDateKey);
  useValidationArrivalDate((val) => (required ? !!val : true), [required], 'transferable,submittable:required');

  const today = useMemo(() => DateTime.now(), []);
  useValidationArrivalDate(
    (val, { isDirty }) => (isDirty && val != null && val.diff(today).as('milliseconds') < 0 ? 'error.dateInThePast' : true),
    [today],
    'change:past',
  );

  const useMapperArrivalDate = useFieldMapper(jobStageBaseFormContext, fieldArrivalDateKey);
  useMapperArrivalDate((val) => ({ projectBase: { arrivalDate: val?.toJSON() ?? null } }), [], 'save');

  const arrivalDateIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldArrivalDateKey);

  const renderArrivalDate = useCallback(
    () => <ArrivalDate value={arrivalDate} setValue={setArrivalDate} disabled={disabled} required={required} />,
    [arrivalDate, disabled, required, setArrivalDate],
  );

  return { arrivalDate, setArrivalDate, arrivalDateIsDirty, renderArrivalDate, useValidationArrivalDate };
}

function ArrivalDate({
  value,
  setValue,
  disabled,
  required,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldArrivalDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldArrivalDateKey);

  const handleChange = useCallback((v: DateTime | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => setValue(v), [setValue]);

  return (
    <RoundedDateTimePicker
      label={t('field.project.date.arrival')}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          inputProps: {
            'data-label-key': 'field.project.date.arrival',
          },
          error: hasErrors,
          required,
          helperText: message && t(message, { ...errorParams }),
        },
      }}
      disablePast
    />
  );
}

const lengthInDaysMinValue = 1;
const lengthInDaysMaxValue = 365;
const hoursPerDayMinValue = 1;
const hoursPerDayMaxValue = 16;

const fieldDurationLengthInDaysKey = createFieldKey<number | null>();

export function useFieldDurationLengthInDays(
  $key: ProjectBaseFields_DurationLengthInDaysFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationLengthInDaysFragment on ProjectInternalBase {
        duration {
          lengthInDays
        }
      }
    `,
    $key,
  );

  const [durationLengthInDays, setDurationLengthInDays] = useField(
    jobStageBaseFormContext,
    fieldDurationLengthInDaysKey,
    $data?.duration?.lengthInDays ?? null,
  );

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldDurationLengthInDaysKey);
  useValidation((v) => !!v && v <= lengthInDaysMaxValue, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldDurationLengthInDaysKey);
  useMapper((v) => ({ projectBase: { duration: { lengthInDays: v } } }), [], 'save');

  const renderDurationLengthInDays = useCallback(
    (input$key: ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <DurationLengthInDaysInput
        $key={input$key}
        saleKind={saleKind}
        value={durationLengthInDays}
        setValue={setDurationLengthInDays}
        disabled={disabled}
      />
    ),
    [disabled, durationLengthInDays, setDurationLengthInDays],
  );

  const renderDurationLengthInDaysNoSuggestions = useCallback(
    () => <DurationLengthInDaysInput_NoSuggestions value={durationLengthInDays} setValue={setDurationLengthInDays} disabled={disabled} />,
    [disabled, durationLengthInDays, setDurationLengthInDays],
  );

  return { durationLengthInDays, setDurationLengthInDays, renderDurationLengthInDays, renderDurationLengthInDaysNoSuggestions };
}

function DurationLengthInDaysInput_NoSuggestions({
  value,
  setValue,
  disabled,
}: {
  value: number | null;
  setValue: SetValueFn<number | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((v: number | null, _: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(v), [setValue]);

  return (
    <NumberInput
      label={t('field.project.date.duration')}
      min={lengthInDaysMinValue}
      max={lengthInDaysMaxValue}
      step={1}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {t('unit.full.day', {
              count: value ?? 0,
              ns: 'common',
            })}
          </InputAdornment>
        ),
      }}
    />
  );
}

function DurationLengthInDaysInput({
  $key,
  saleKind,
  value,
  setValue,
  disabled,
}: {
  $key: ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment$key | null | undefined;
  saleKind: ServiceCallKind;
  value: number | null;
  setValue: SetValueFn<number | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldDurationLengthInDaysKey);

  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationLengthInDaysInput_SuggestionsFragment on IJobStage
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback((v: number | null, _: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(v), [setValue]);

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <NumberInput
      label={t('field.project.date.duration')}
      min={lengthInDaysMinValue}
      max={lengthInDaysMaxValue}
      step={1}
      disabled={disabled}
      value={value}
      required
      onChange={handleChange}
      error={hasErrors}
      InputProps={{
        error: hasErrors,
        endAdornment: (
          <>
            <InputAdornment position='end'>
              {t('unit.full.day', {
                count: value ?? 0,
                ns: 'common',
              })}
            </InputAdornment>
            <InputAdornment position='end'>
              <Suggestions
                disabled={disabled}
                content={(close) => (
                  <DurationLengthInDaysSuggestions
                    suggestionPromptInput={suggestionPromptInput}
                    onChange={(v) => {
                      setValue(v);
                      close();
                    }}
                  />
                )}
              />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

function DurationLengthInDaysSuggestions({
  suggestionPromptInput,
  onChange: handleChange,
}: {
  suggestionPromptInput: SuggestionPromptInput | null;
  onChange: (selectedValue: number) => void;
}) {
  const query = useLazyLoadQuery<ProjectBaseFields_DurationLengthInDays_SuggestionsQuery>(
    graphql`
      query ProjectBaseFields_DurationLengthInDays_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedDurationInDays(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const data = query.suggestedDurationInDays ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: number) => handleChange(selectedValue)} />
  );
}

const fieldDurationHoursPerDayKey = createFieldKey<number | null>();

export function useFieldDurationHoursPerDay($key: ProjectBaseFields_DurationHoursPerDayFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationHoursPerDayFragment on ProjectInternalBase {
        duration {
          hoursPerDay
        }
      }
    `,
    $key,
  );

  const [durationHoursPerDay, setDurationHoursPerDay] = useField(
    jobStageBaseFormContext,
    fieldDurationHoursPerDayKey,
    $data?.duration?.hoursPerDay ?? null,
  );

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldDurationHoursPerDayKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldDurationHoursPerDayKey);
  useMapper((v) => ({ projectBase: { duration: { hoursPerDay: v } } }), [], 'save');

  const renderDurationHoursPerDay = useCallback(
    (input$key: ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <DurationHoursPerDayInput
        $key={input$key}
        value={durationHoursPerDay}
        setValue={setDurationHoursPerDay}
        saleKind={saleKind}
        disabled={disabled}
      />
    ),
    [disabled, durationHoursPerDay, setDurationHoursPerDay],
  );

  const renderDurationHoursPerDay_NoSuggestions = useCallback(
    () => <DurationHoursPerDayInput_NoSuggestions value={durationHoursPerDay} setValue={setDurationHoursPerDay} disabled={disabled} />,
    [disabled, durationHoursPerDay, setDurationHoursPerDay],
  );

  return { durationHoursPerDay, setDurationHoursPerDay, renderDurationHoursPerDay, renderDurationHoursPerDay_NoSuggestions };
}

function DurationHoursPerDayInput_NoSuggestions({
  value,
  setValue,
  disabled,
}: {
  value: number | null;
  setValue: SetValueFn<number | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((v: number | null, _: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(v), [setValue]);

  return (
    <NumberInput
      label={t('field.project.date.durationPerDay')}
      min={hoursPerDayMinValue}
      max={hoursPerDayMaxValue}
      step={0.5}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {t('unit.full.hour', {
              count: value ?? 0,
              ns: 'common',
            })}
          </InputAdornment>
        ),
      }}
    />
  );
}

function DurationHoursPerDayInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key | null | undefined;
  value: number | null;
  setValue: SetValueFn<number | null>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldDurationHoursPerDayKey);

  const $data = useFragment(
    graphql`
      fragment ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment on IJobStage
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback((v: number | null, _: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(v), [setValue]);

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <NumberInput
      label={t('field.project.date.durationPerDay')}
      min={hoursPerDayMinValue}
      max={hoursPerDayMaxValue}
      step={0.5}
      disabled={disabled}
      value={value}
      required
      onChange={handleChange}
      error={hasErrors}
      InputProps={{
        error: hasErrors,
        endAdornment: (
          <>
            <InputAdornment position='end'>
              {t('unit.full.hour', {
                count: value ?? 0,
                ns: 'common',
              })}
            </InputAdornment>
            <InputAdornment position='end'>
              <Suggestions
                disabled={disabled}
                content={(close) => (
                  <DurationHoursPerDaySuggestions
                    suggestionPromptInput={suggestionPromptInput}
                    onChange={(v) => {
                      setValue(v);
                      close();
                    }}
                  />
                )}
              />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

function DurationHoursPerDaySuggestions({
  suggestionPromptInput,
  onChange: handleChange,
}: {
  suggestionPromptInput: SuggestionPromptInput | null;
  onChange: (selectedValue: number) => void;
}) {
  const query = useLazyLoadQuery<ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery>(
    graphql`
      query ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedHoursPerDay(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const data = query.suggestedHoursPerDay ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: number) => handleChange(selectedValue)} />
  );
}
