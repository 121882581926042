import { Suspense, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ListLayout, SidebarContentProps } from '../../layout/Layouts';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { namedPageTitle, usePageTitle } from '../../common/hooks/usePageTitle';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { Button, Skeleton } from '@mui/material';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { BillingCodeRulesTabs } from '../BillingCodeRulesTabs';
import { AccessoryRuleList } from './AccessoryRuleList';
import { TabAccessoryRulesQuery } from './__generated__/TabAccessoryRulesQuery.graphql';
import { BillingCodeRulesPageOutletContext } from '../BillingCodeRulesPage';

export function TabAccessoryRules() {
  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.billingCodeRules'));
  const navigate = useNavigate();
  const $key = useOutletContext<BillingCodeRulesPageOutletContext>().tabAccessoryRules$key;

  const $data = useFragment(
    graphql`
      fragment TabAccessoryRulesFragment on Query {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
      }
    `,
    $key,
  );

  const handleCreateClick = useCallback(() => navigate('/billing-code-rules/accessory-rules/new'), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const flagName = 'app_navigation_configuration';

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('accessoryRule')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout
        heading={t('title')}
        flagName={flagName}
        sidebarProvider={sidebar}
        $key={$data}
        actions={<Button onClick={handleCreateClick}>{t('button.create', { ns: 'common' })}</Button>}>
        <ErrorBanner />
        <BillingCodeRulesTabs tab='accessory-rules'></BillingCodeRulesTabs>

        <Suspense fallback={<ContentSkeleton />}>
          <BillingCodeRuleAccessoriesListContent />
        </Suspense>
      </ListLayout>
    </RequireAdmin>
  );
}

function BillingCodeRuleAccessoriesListContent() {
  const $data = useLazyLoadQuery<TabAccessoryRulesQuery>(
    graphql`
      query TabAccessoryRulesQuery {
        ...AccessoryRuleListFragment
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  return <AccessoryRuleList $key={$data} />;
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
