/**
 * @generated SignedSource<<c11230dd66446316aec8f0212bcf3cfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useInstantCalculator_useSalesRateInputFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
    };
  };
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_NatureOfWorkFragment">;
  };
  readonly projectBase: {
    readonly assignedWorksiteInfo: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_LocationFragment" | "ProjectBaseFields_WorksiteOverrides_NameFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment" | "ProjectBaseFields_AssignedWorksiteFragment">;
  };
  readonly " $fragmentType": "useInstantCalculator_useSalesRateInputFragment";
};
export type useInstantCalculator_useSalesRateInputFragment$key = {
  readonly " $data"?: useInstantCalculator_useSalesRateInputFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInstantCalculator_useSalesRateInputFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useInstantCalculator_useSalesRateInputFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_AssignedClientFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isCopy",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_AssignedWorksiteFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksiteInfoInternal",
          "kind": "LinkedField",
          "name": "assignedWorksiteInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_NameFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectBaseFields_WorksiteOverrides_LocationFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobEquipment_useCraneSelectorFavoriteFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "9ee4f23703aa8d08592a2dacab6c3300";

export default node;
