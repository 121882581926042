import { ChangeEvent, ReactElement, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { DataID, Disposable, fetchQuery, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { defaultLogger, Logger } from '../common/utils/logging';
import { _throw } from '../common/utils/_throw';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { CraneChartList, CraneChartListHandle } from './CraneChartList';
import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { TabCraneChartsQuery } from './__generated__/TabCraneChartsQuery.graphql';
import { TabCraneChartsErrorLogQuery } from './__generated__/TabCraneChartsErrorLogQuery.graphql';
import {
  StartCraneChartBlobUploadInput,
  TabCraneChartsStartBlobUploadMutation,
} from './__generated__/TabCraneChartsStartBlobUploadMutation.graphql';
import {
  CompleteCraneChartBlobUploadInput,
  TabCraneChartsCompleteBlobUploadMutation,
} from './__generated__/TabCraneChartsCompleteBlobUploadMutation.graphql';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useOutletContext } from 'react-router-dom';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { FileImport, UploadError, uploadFiles } from '../common/utils/fileUpload';
import { ErrorBanner, ErrorStateProvider, useErrorBanner } from '../common/components/ErrorBanner';
import LoadingButton from '@mui/lab/LoadingButton';
import { LayoutsListLayoutFragment$key } from '../layout/__generated__/LayoutsListLayoutFragment.graphql';
import { useEffectEvent } from '../common/utils/effectUtils';

interface UploadStateBase {
  /**
   * Make every prop of UploadState accessible via an indexer which is required
   * to use it as a translation option.
   */
  [key: string]: unknown;
}

interface IdleUploadState extends UploadStateBase {
  stage: 'idle';
}

interface UploadingUploadState extends UploadStateBase {
  stage: 'uploading';
}

interface SuccessUploadState extends UploadStateBase {
  stage: 'success';
  count: number;
}

interface ErrorUploadState extends UploadStateBase {
  stage: 'error';
  error: unknown;
}

type UploadState = IdleUploadState | UploadingUploadState | SuccessUploadState | ErrorUploadState;

const logger = new Logger(defaultLogger, 'BlobUpload', () => new Date().toISOString());
function useCraneChartBlobUpload(onProgress: () => void) {
  const { t } = useTranslation('configuration');
  const { reportHandledError } = useErrorBanner();

  const [startBlobUpload] = useMutation<TabCraneChartsStartBlobUploadMutation>(graphql`
    mutation TabCraneChartsStartBlobUploadMutation($inputs: [StartCraneChartBlobUploadInput!]!) {
      startCraneChartBlobUpload(inputs: $inputs) {
        craneChartBlobSasUris {
          craneChartBlobMetadata {
            id
            name
            byteCount
            mimeType
            storageContainer
            status
          }
          sasUri
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);
  const startBlobUploadSubscription = useRef<Disposable>();

  const [completeBlobUpload] = useMutation<TabCraneChartsCompleteBlobUploadMutation>(graphql`
    mutation TabCraneChartsCompleteBlobUploadMutation($inputs: [CompleteCraneChartBlobUploadInput!]!) {
      completeCraneChartBlobUpload(inputs: $inputs) {
        craneChartBlobMetadatas {
          id
          name
          byteCount
          mimeType
          storageContainer
          status
        }
        errors {
          __typename
        }
      }
    }
  `);
  const completeBlobUploadSubscription = useRef<Disposable>();

  const [startedUploads, setStartedUploads] = useState<FileImport[]>([]);
  const [completedUploads, setCompletedUploads] = useState<FileImport[]>([]);
  const [uploadState, setUploadState] = useState<UploadState>({ stage: 'idle' });

  const resetState = useCallback(() => {
    setUploadState((prev) => {
      if (prev.stage === 'success' || prev.stage === 'error') {
        return { stage: 'idle' };
      }
      return prev;
    });
  }, []);

  const handleImportClick = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (uploadState.stage === 'uploading') {
        return;
      }

      const fileList = e.target.files;
      if (!fileList?.length) {
        return;
      }

      setUploadState({ stage: 'uploading' });

      const files = Array.from(fileList);
      const inputs = files.map(
        (file): StartCraneChartBlobUploadInput => ({
          name: file.name,
          byteCount: file.size,
        }),
      );
      startBlobUploadSubscription.current = startBlobUpload({
        variables: { inputs },
        onCompleted(response, errors) {
          startBlobUploadSubscription.current = undefined;
          if (errors != null && errors.length > 0) {
            for (const error of errors) {
              captureException(error);
              logger.error(error);
            }
            setUploadState({ stage: 'error', error: errors[0] });
          } else if (response?.startCraneChartBlobUpload?.errors?.length) {
            reportHandledError(response.startCraneChartBlobUpload.errors, () => t('error.errorDuringUpload'));

            // TODO: This shows another banner, does it affect the status of the upload ?
            // Most of the time the errors here did not even trigger an upload (e.g validation failure)
            // setUploadState({ stage: 'error', error: response.startCraneChartBlobUpload.errors[0] });
          } else {
            setStartedUploads(
              response.startCraneChartBlobUpload.craneChartBlobSasUris?.map((bsu) => ({
                file: files.find((f) => f.name === bsu.craneChartBlobMetadata.name) ?? _throw(new Error(t('craneCharts.unexpectedError'))),
                blobMetadataId: bsu.craneChartBlobMetadata.id,
                mimeType: bsu.craneChartBlobMetadata.mimeType,
                sasUri: bsu.sasUri,
              })) ?? [],
            );
          }

          onProgress();
        },
      });
      e.target.value = '';
    },
    [uploadState.stage, startBlobUpload, onProgress, reportHandledError, t],
  );

  const uploadFilesEvent = useEffectEvent((uploads: FileImport[]) => {
    onProgress();

    uploadFiles({
      uploads: uploads,
      onError: (errors: UploadError[]) => {
        errors.forEach((error) => {
          captureException(error);
          logger.error(error);
        });
      },
      onSuccess: (files) => {
        setCompletedUploads(files);
      },
    });
  });

  useEffect(() => {
    if (!startedUploads.length || startBlobUploadSubscription.current) {
      return;
    }

    uploadFilesEvent(startedUploads);
  }, [startedUploads, uploadFilesEvent]);

  const completeEvent = useEffectEvent((uploads: FileImport[]) => {
    onProgress();

    // Hack: Adds a little frontend timeout to notify the user they've been waiting too long and force them to refresh the page
    // Note: This does not break the background processing if it still happens, so on page refresh the user will get all
    // The latest crane charts with their latest status (most likely)
    const processingTimeout = setTimeout(
      () => {
        setUploadState({ stage: 'error', error: new Error('Upload process timeout') });
        setStartedUploads([]);
        setCompletedUploads([]);
        onProgress();
        completeBlobUploadSubscription.current?.dispose();
        completeBlobUploadSubscription.current = undefined;
      },
      uploads.length * 10 * 1000, //< 10 seconds timeout per item, which _should_ be a lot
    );

    completeBlobUploadSubscription.current = completeBlobUpload({
      variables: {
        inputs: uploads.map((file): CompleteCraneChartBlobUploadInput => ({ id: file.blobMetadataId })),
      },
      onCompleted(_, errors) {
        completeBlobUploadSubscription.current = undefined;
        onProgress();
        clearTimeout(processingTimeout);
        setStartedUploads([]);
        setCompletedUploads([]);
        if (errors != null && errors.length > 0) {
          setUploadState({ stage: 'error', error: errors[0] });
          for (const error of errors as unknown[]) {
            captureException(error);
            logger.error(error);
          }
        } else {
          setUploadState({ stage: 'success', count: completedUploads.length });
        }
      },
      onError(error) {
        completeBlobUploadSubscription.current = undefined;
        onProgress();
        setStartedUploads([]);
        setCompletedUploads([]);
        clearTimeout(processingTimeout);
        if (error) {
          captureException(error);
          logger.error(error);
          setUploadState({ stage: 'error', error: error });
        }
      },
    });
  });

  useEffect(() => {
    if (!completedUploads.length || completeBlobUploadSubscription.current) {
      return;
    }

    completeEvent(completedUploads);
  }, [completeBlobUpload, completeEvent, completedUploads]);

  return {
    uploadState,
    handleImportClick,
    resetState,
  };
}

export function TabCraneCharts() {
  const { t } = useTranslation('configuration');
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  usePageTitle(namedPageTitle('sidebar.configuration'));

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('configuration')} flagName='app_navigation_configuration' sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <TabCraneChartsContent listLayout$key={$data} sidebar={sidebar} />
    </RequireAdmin>
  );
}

function TabCraneChartsContent({
  sidebar,
  listLayout$key,
}: {
  listLayout$key: LayoutsListLayoutFragment$key | null | undefined;
  sidebar: (props: SidebarContentProps) => ReactElement;
}) {
  const { t } = useTranslation('configuration');
  const listRef = useRef<CraneChartListHandle | null>(null);
  const { uploadState, handleImportClick, resetState } = useCraneChartBlobUpload(() => listRef.current?.refresh());
  const isUploading = uploadState.stage === 'uploading';
  const importSuccess = uploadState.stage === 'success';
  const showDialog = importSuccess || uploadState.stage === 'error';
  const severity = importSuccess ? 'success' : 'error';
  const environment = useRelayEnvironment();
  const [, setSubscription] = useCancellableSubscription();
  const craneCharts = useLazyLoadQuery<TabCraneChartsQuery>(
    graphql`
      query TabCraneChartsQuery($first: Int) {
        ...CraneChartListFragment @arguments(first: $first)
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  const handleItemClick = useCallback(
    (id: DataID) => {
      setSubscription(
        fetchQuery<TabCraneChartsErrorLogQuery>(
          environment,
          graphql`
            query TabCraneChartsErrorLogQuery($id: ID!) {
              node(id: $id) {
                ... on CraneChartBlobMetadata {
                  status
                  storageErrorFileName
                  errorLogUrl
                }
              }
            }
          `,
          { id: id },
          { fetchPolicy: 'network-only' },
        ).subscribe({
          next: (data) => {
            if (data.node && data.node.status === 'error' && data.node.errorLogUrl) {
              const link = document.createElement('a');
              link.href = data.node.errorLogUrl;
              link.download = data.node.storageErrorFileName || '';
              document.body.appendChild(link);
              link.click();
              link.parentNode?.removeChild(link);
            }
          },
        }),
      );
    },
    [setSubscription, environment],
  );
  const flagName = 'app_navigation_configuration';

  return (
    <ErrorStateProvider>
      <ListLayout
        heading={t('configuration')}
        flagName={flagName}
        sidebarProvider={sidebar}
        $key={listLayout$key}
        actions={
          <LoadingButton loading={isUploading} component='label'>
            {t('button.import', { ns: 'common' })}
            <input data-testid='upload.input' type='file' accept='.xlsx' multiple hidden onChange={handleImportClick} />
          </LoadingButton>
        }>
        <ErrorBanner />
        <ConfigurationTabs tab='crane-charts'></ConfigurationTabs>
        {showDialog && (
          <Alert data-testid='upload.alert' severity={severity} onClose={() => resetState()}>
            {uploadState.stage === 'success' && t('craneCharts.importSuccessMessage', uploadState)}
            {uploadState.stage === 'error' && t('craneCharts.importErrorMessage')}
          </Alert>
        )}
        <Suspense fallback={<ContentSkeleton />}>
          <CraneChartList ref={listRef} onItemClick={handleItemClick} fragmentKey={craneCharts} />
        </Suspense>
      </ListLayout>
    </ErrorStateProvider>
  );
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
