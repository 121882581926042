/**
 * @generated SignedSource<<3a3a55b003dccfb887ed4f22ef5265d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AddendumKind = "archived" | "clientOrWorksite" | "craneOperator" | "dispatching" | "internalNote" | "invoicing" | "quote" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateListItemFragment$data = {
  readonly id: string;
  readonly kind: AddendumKind;
  readonly title: Record<string, string>;
  readonly " $fragmentType": "AddendumTemplateListItemFragment";
};
export type AddendumTemplateListItemFragment$key = {
  readonly " $data"?: AddendumTemplateListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "bb5b0912bea73b080a38eea286685608";

export default node;
