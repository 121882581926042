import { WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { NatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { PowerlineProximity } from '../../__enums__/PowerlineProximity';
import { TransportSegmentKind } from '../../__enums__/TransportSegmentKind';

const button = {
  add: 'Add',
  cancel: 'Cancel',
  clear: 'Clear',
  clearFilters: 'Clear Filters',
  close: 'Close',
  confirm: 'Confirm',
  copy: 'Copy',
  create: 'Create',
  delay: 'Delay',
  delete: 'Delete',
  done: 'Done',
  edit: 'Edit',
  email: 'Email',
  filter: 'Filter',
  filters: 'Filters',
  goBack: 'Go back',
  goHome: 'Go Home',
  import: 'Import',
  next: 'Next',
  notify: 'Notify',
  ok: 'Ok',
  previous: 'Previous',
  retry: 'Retry',
  returnToPreviousPage: 'Return to previous page',
  save: 'Save',
  saveCopy: 'Save the copy',
  select: 'Select',
  submit: 'Submit',
  transfer: 'Transfer',
  update: 'Update',
};

const dateTime = {
  today: 'Today',
};

const field = {
  addendumTemplates: 'Addendum Templates',
  duration: 'Duration',
  durationPerDay: 'Duration per Day',
  requirements: 'Requirements',
};

const placeholder = {
  deleteConfirmation: 'Do you really want to delete this element?',
  noValue: 'No Value',
  noSuggestions: 'No suggestions',
};

const unit = {
  datetime: {
    short: {
      hour: 'h',
    },
  },
  full: {
    day_one: 'day',
    day_other: 'days',
    degree_one: 'degree',
    degree_other: 'degrees',
    hour_one: 'hour',
    hour_other: 'hours',
    pound_one: '{{ count }} pound',
    pound_other: '{{ count }} pounds',
    ton_one: '{{ count }} Ton',
    ton_other: '{{ count }} Tons',
    feetInches: 'feet/inches',
  },
  short: {
    pound: 'lb',
    price: '${{ price }}',
    ton: 'T',
  },
  mass: {
    short: {
      lbs: 'lbs',
      kg: 'kg',
    },
  },
  length: {
    short: {
      km: 'km',
      m: 'm',
      mm: 'mm',
      cm: 'cm',
      in: 'in',
      ft: 'ft',
      ftin: 'ft/in',
    },
  },
  angle: {
    short: {
      deg: '°',
    },
  },
  rate: {
    short: {
      hourly: '$/h',
    },
  },
  speed: {
    short: {
      'km/h': 'km/h',
    },
  },
};

const yesNo = {
  yes: 'Yes',
  no: 'No',
};

const workSchedules: Record<WorkScheduleEnum, string> = {
  fiveDaysEightHours: '5d x 8h',
  fiveDaysNineHours: '5d x 9h',
  fiveDaysTenHours: '5d x 10h',
  fourDaysTenHours: '4d x 10h',
  fourDaysTenHoursOneDayFiveHours: '4d x 10h + 1d x 5h',
};

const natureOfWorkSubCategories: Record<NatureOfWorkSubCategoryEnum, string> = {
  airports: 'Airports',
  landscaping: 'Landscaping',
  antennas: 'Antennas',
  aqueductsSewer: 'Aqueducts and Sewers',
  dams: 'Dams',
  retentionBasins: 'Retention basins',
  blade: 'Blade Bearing',
  cages: 'Moving cages',
  powerPlants: 'Power plants',
  chambers: 'Chambers',
  demolition: 'Demolition',
  distribution: 'Distribution',
  pruning: 'Pruning',
  excavation: 'Excavation',
  gearboxes: 'Gearboxes',
  generator: 'Generator',
  lifting: 'Equipment lifting',
  lines: 'Lines',
  accessory: 'Accessory rental',
  nonOperated: 'Non-operated rental',
  hand: 'Hand',
  handling: 'Handling',
  modules: 'Modules',
  gondola: 'Gondola/Suspended basket',
  newConstruction: 'New construction/Expansion',
  bridges: 'Bridges/Viaducts',
  substations: 'Substations/Stations',
  pylons: 'Pylons',
  docks: 'Docks',
  refurbishment: 'Refurbishment',
  rotor: 'Rotor',
  roadsRails: 'Roads/Rails/Subways',
  shelters: 'Shelters',
  towers: 'Towers',
  transformers: 'Transformers',
  transport: 'Transport',
  ventilationPlumbingElectrical: 'Ventilation/Plumbing/Electrical',
};

const autocomplete = {
  clearText: 'Clear',
  closeText: 'Close',
  loadingText: 'Loading…',
  noOptionsText: 'No options',
  openText: 'Open',
  automaticSuggestions: 'Automatic suggestions',
};

export const common = {
  autocomplete,
  automatic: 'Automatic',
  button,
  dateTime,
  dialog: {
    noResult: 'No result found',
  },
  displayedRows: '{{ info.from }}-{{ info.to }} of {{ info.count }}',
  errorMessages: {
    accessDeniedError: 'Access is denied',
    archivedError: 'The current revision is archived and readonly',
    canceledError: 'The current revision is canceled and readonly',
    costLineIsRequired: 'Please create a cost line',
    elementNotFound: {
      body: 'The ID number for $t(errorMessages.elementNotFound.names.{{ element }}) does not exist, please return to the previous page.',
      names: {
        accessoryLine: 'the accessory line',
        addendum: 'the addendum',
        addendumTemplate: 'the addendum template',
        attachment: 'the attachment',
        billingCodeRule: 'the billing code rule',
        accessoryRule: 'the accessory rule',
        costLine: 'the cost line',
        lift: 'the lift',
        quote: 'the quote',
        serviceCall: 'the service call',
        workPlanning: 'the work planning',
        rateStrategy: 'the rate strategy',
      },
      subtitle: 'Please return to the previous page',
      title: 'Element not found',
    },
    entityNotFoundError: 'The element was not found',
    errorDuringCancel: 'An error occurred during the cancellation',
    errorDuringCopy: 'An error occurred during the copy',
    errorDuringSave: 'An error occurred during the save',
    errorOccurred: 'An error occurred',
    invalidJobStageBaseTransitionError: 'Cannot transition from {{ typeFrom }} to {{ typeTo }}',
    invalidNumber: 'Invalid number',
    invalidPatchOperationError: 'An operation on a list of item was invalid',
    jobRevisionIsNotTheLatestError: '{{ jobRevisionType }} has changed, please refresh the page',
    jobStageLockedError: 'The current revision is locked and readonly',
    mustBeUnique: 'Must be unique',
    patchOperationItemNotFoundError: 'An operation on a list of item was invalid',
    requiredField: 'Required Field',
    save: 'An error occurred during the save',
    somethingWentWrong: 'Something went wrong',
    unknownError: 'Unknown error',
    unsavedChanges: 'Changes you made may not be saved.',
    uploadFailed: 'Error while uploading, try again later',
    validation: {
      shouldBeInWritingReservedOrDelayed: 'Status must be in writing, reserved or delayed',
      accessoryTypeNotFound: 'The accessory type is required',
      automaticMustBeSubsetOfVisible: 'Automatic billing codes must come from the selected visible billing codes',
      billingCodeNotFound: 'The billing code was not found',
      craneNotFoundOrInactive: 'The crane of the cost line #{{ index }} was not found or is inactive',
      craneSelectorETagMismatch: 'The results of the crane selector are outdated, please refresh them',
      craneSelectorSnapshotMismatch: 'The selected crane data was updated, please search and select again',
      entityNotFound: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) was not found',
      equal: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be equal to {{ comparisonValue }}',
      greaterThan: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be greater than {{ comparisonValue }}',
      greaterThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be greater than or equal to {{ comparisonValue }}',
      integerQuantity: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be an integer quantity',
      invalidFavoriteETag: 'The configuration selection is invalid',
      invalidFileName: 'The file name "{{ actualValue }}" is invalid',
      invalidHourPerDay: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) is invalid',
      invalidPhoneNumber: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be a valid phone number',
      invalidPrice: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must match the price list',
      invalidQuantity:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be greater than or equal to the minimum quantity',
      invalidStateTransitionToInModification: 'Status must be in modification or transferred',
      invalidStateTransitionToTransfer: 'Status must be reserved, delayed, in modification or transferred',
      itemTypeCodeIsRequired: 'Labor is required and must be active',
      lessThan: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be less than {{ comparisonValue }}',
      lessThanArrivalDate: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be less than the arrival date',
      lessThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be less than or equal to {{ comparisonValue }}',
      lessThanReadyDate: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be less than the ready date',
      maximumLength:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) has a maximum length of {{ comparisonValue }}',
      mismatchSalesRates: 'The sales rates of a cost line is missing or mismatching',
      multipleQuantity: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) quantity must be a multiple of a quarter',
      mustBePositiveInteger: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be a positive integer',
      mustBeStrictlyPositiveInteger:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be a strictly positive integer',
      mustHaveFavorite: 'There must be a preferred crane configuration selected',
      noSituationSelected: 'A minimum of one particular situation must be selected',
      notEmpty: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be filled',
      notInThePast: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must not be in the past',
      notNull: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must be filled',
      notSpecified: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) must have a value',
      shouldBeInStatus: 'Status must be $t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" })',
      shouldBeInWritingOrUnderReviewOrRevising: 'Status must be in writing, under review or revising',
      shouldBeInWritingOrWorksiteSelected: 'Status must be in writing or worksite selected',
      shouldBeUnderReview: 'Status must be under review',
      shouldNotBeCanceledArchivedOrDispatched: 'Status must not be canceled, archived, transferred or dispatched',
      shouldNotBeInStatus: 'Status must not be {{ comparisonValue }}',
      shouldNotBeTransferredOrDispatched: 'Status must not be transferred or dispatched',
      softDeleted: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) is inactive',
      totalRadiusMustBeStrictlyPositiveInteger: 'Total radius must be a strictly positive integer',
    },
  },
  field,
  fileUpload: {
    status: {
      queued: 'Queued',
      uploading: 'Uploading',
      received: 'Received',
      processing: 'Processing',
      error: 'Error',
      success: 'Success',
    },
  },
  inactive: 'Inactive',
  jobRevisionType: {
    quote: 'The quote',
    serviceCall: 'The service call',
    workPlanning: 'The work planning',
  },
  // keep in sync with languageToLocale in src/i18n.ts
  locale: 'en-CA',
  maintenancePlaceholder: 'The App is currently unavailable, please come back later.',
  maintenanceTitle: 'WeLift Maintenance',
  natureOfWorkSubCategories,
  noResult: 'No result found',
  placeholder,
  propertyNames: {
    // Note: The case is important since they come as PascalCase from the backend...
    ActiveValidator: {
      AccessoryGroupId: 'The accessory',
      AssignedClientId: 'The client',
      AssignedWorksiteId: 'The worksite',
      BillingCodeId: 'The billing code',
      Crane: 'The crane',
      CraneConfiguration: 'The crane configuration',
      CraneConfigurationInfo: 'The crane configuration',
      DispatchBranchId: 'The dispatch branch',
      MatchingConfigurations: 'The crane selector',
      ProjectManagerId: 'The project manager',
      SalesBranchId: 'The sales branch',
    },
    Addenda: {
      Title: 'The title of the addenda #{{ index }}',
      Description: 'The description of the addenda #{{ index }}',
    },
    BillingCodes: 'Billed',
    Building: {
      Value: {
        ExteriorRadius: {
          Value: 'Exterior radius',
        },
      },
    },
    BoomLength: 'The boom length',
    Client: {
      InTheCareOf: 'I/C',
      OrderNumber: 'PO #',
      ProjectManagerId: 'The project manager',
    },
    ClientBase: {
      AssignedClientId: 'The client',
      AssignedClientInfo: {
        Name: 'The client name',
        'Location.Address': 'The client address',
        Contact: 'The client contact',
        PhoneNumber: 'The client phone number',
      },
    },
    CostsBase: {
      CostLine: 'A cost line',
      CostLines: {
        Quantity: 'The field quantity of cost line #{{ index }}',
        WorkForceTypeCode: 'The labor of the cost line #{{ index }}',
        Rate: 'The rate of the cost line #{{ index }}',
        SalesRateResult: 'The result of the external sales rate service',
      },
      AccessoryLines: {
        AccessoryGroupId: 'The accessory of the accessory line #{{ index }}',
        BillingCodeId: 'Billed of the accessory line #{{ index }}',
        Quantity: 'The field quantity of accessory line #{{ index }}',
        AccessoryOutOfInventory: 'The accessory of the accessory line #{{ index }}',
        Rate: 'The rate of the accessory line #{{ index }}',
        SalesRateResult: 'The result of the external sales rate service',
      },
    },
    Counterweight: 'The counterweight',
    Description: 'Description',
    EquipmentBase: {
      CraneSelector: {
        AutomaticConfiguration: {
          MatchingConfigurations: 'The crane selector',
          SnapshotId: 'The snapshot identifier',
        },
        ManualConfiguration: {
          AdditionalConfigurations: {
            Capacity: 'The capacity of the additional crane',
            EquipmentKindCode: 'The equipment kind of the additional crane',
            ConfigurationKindCode: 'The configuration kind of the additional crane',
            BoomConfigurationId: 'The boom configuration of the additional crane',
          },
          Configuration: {
            BoomLength: 'The boom length of required equipment',
            Capacity: 'The capacity of required equipment',
            ConfigurationKindCode: 'The configuration kind of required equipment',
            Counterweight: 'The counterweight',
            EquipmentKindCode: 'The equipment kind of required equipment',
            MaxWeight: 'The max weight of required equipment',
            Radius: 'The radius of required equipment',
            VehicleIds: 'The make/model list',
            BoomConfigurationId: 'The boom configuration',
          },
          CraneConfigurations: 'The crane configurations',
        },
      },
    },
    Equipment: {
      BranchToWorksiteDistance: 'The distance between branch and worksite',
    },
    JibLength: 'The jib length',
    Project: {
      DepartureDate: 'The departure date',
      DispatchBranchId: 'The dispatch branch',
      NatureOfWorkCode: 'The nature of work',
      NatureOfWorkSubCategory: 'The nature of work category',
      ReadyDate: 'The ready date',
      SalesBranchId: 'The sales branch',
      WorkDescription: 'The work description',
      WorkSchedule: 'The work schedule',
      SchedulePeriod: {
        StartTime: 'The start time',
        EndTime: 'The end time',
      },
    },
    ProjectBase: {
      ArrivalDate: 'The arrival date',
      AssignedWorksiteId: 'The worksite',
      AssignedWorksiteInfo: {
        Name: 'The worksite name',
        'Location.Address': 'The worksite address',
        Contact: 'The worksite contact',
        PhoneNumber: 'The worksite phone number',
      },
      Duration: {
        LengthInDays: 'Job Duration',
        HoursPerDay: 'The duration per day',
      },
    },
    QuestionsBase: {
      SalesQuestions: {
        Option: 'The proximity to power lines',
        Angle: 'The angle of the information of the excavation',
        Distance: 'The distance of the information of the excavation',
        Proximity: 'The proximity to power lines',
      },
      QuestionsInternalBase: {
        HasWorksiteBeenVisited: 'Has a worksite visit been done',
      },
    },
    RateStrategy: {
      LabelEn: 'The english label',
      LabelFr: 'The french label',
    },
    WorkSchedule: {
      LengthInDays: 'Length in days',
      HoursPerDay: 'Hours per day',
    },
  },
  proximityToPowerline: {
    lessThan126KV: '125KV and less: 3m',
    from126KVTo250KV: '126KV to 250KV: 5m',
    from251KVTo550KV: '251KV to 550KV: 8m',
    moreThan550KV: '551KV and more: 12m',
  } satisfies Record<PowerlineProximity, string>,
  requestSupport: 'Contact the support team',
  resolvers: {
    boomConfiguration: {
      boom: 'Boom',
      jib: 'Jib',
      counterweight: 'Counterweight',
      sequence: 'Sequence',
    },
  },
  saleKinds: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare Crane',
    bareWithOperators: 'Bare Crane with Operators',
    laborRental: 'Labor Rental',
    laborRentalOnBare: 'Labor Rental on Bare',
    laborRentalOnOperated: 'Labor Rental on Operated',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    operatedHourly: 'Operated Crane (hourly)',
    operatedMonthly: 'Operated Crane (monthly)',
    rollingEquipment: 'Rolling Equipment',
    storage: 'Storage',
  } satisfies Record<QuoteKind | ServiceCallKind, string>,
  salesQuestionKinds: {
    peopleLifting: 'Lifting people',
    hazardousTerrain: 'Slopes, gradients, access roads or terrain considered hazardous',
    powerlineProximity: 'Proximity to power lines',
    craneOrObstacleProximity: 'Proximity to obstacles or cranes',
    excavationProximityLessThan10Feet: "Proximity to an excavation - Less than 10'",
    excavationProximityMoreThan10Feet: "Proximity to an excavation - More than 10'",
    accidentScene: 'Accident scene',
    conventionalCrawlerCranesWithoutTravel: 'Conventional crawler cranes - Without Travel',
    conventionalCrawlerCranesWithTravel: 'Conventional crawler cranes - With Travel',
    luffingJibOrExtensionInstallation: 'Installation of a luffing / fix jib or extension',
    movingCraneOrEquipmentWithoutPulling: 'Moving a crane or other equipment - Without Pulling',
    movingCraneOrEquipmentWithPulling: 'Moving a crane or other equipment - With Pulling',
    tandemLiftingGuayCrane: 'Tandem lifting - Guay Crane',
    tandemLiftingClientCrane: 'Tandem lifting - Client Crane',
    bargeWork: 'Barge work',
    unloadingOnQuay: 'Unloading on quay',
    jettyBridgeViaductDamSlabOrParkingDeck: 'Jetty, bridge, viaduct, dam, slab or parking deck',
    windTurbines: 'Wind turbines',
    clamShellBucketOrGrapple: 'Clamshell bucket or grapple',
    events: 'Events',
    valueOf1MillionOrMore: 'Value of $1 million or more',
    videoOrPhotoShooting: 'Video or photo shooting',
  } satisfies Record<SalesQuestionKind, string>,
  searchResults: 'Search results',
  suggestions: 'Suggestions',
  supportedLanguages: {
    french: 'French',
    english: 'English',
  },
  transportSegmentKinds: {
    departure: 'Departure',
    return: 'Return',
  } satisfies Record<TransportSegmentKind, string>,
  unit,
  warning: 'Warning',
  workSchedules,
  yesNo,
};
