/**
 * @generated SignedSource<<8eca440a84460200eb1b5c87b71fbc7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AddendumKind = "archived" | "clientOrWorksite" | "craneOperator" | "dispatching" | "internalNote" | "invoicing" | "quote" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFieldsKindFragment$data = {
  readonly kind: AddendumKind;
  readonly " $fragmentType": "AddendumTemplateFieldsKindFragment";
};
export type AddendumTemplateFieldsKindFragment$key = {
  readonly " $data"?: AddendumTemplateFieldsKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFieldsKindFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "69720765b5b5bce42245715cbe9796bc";

export default node;
