import { SelectChangeEvent } from '@mui/material';
import Qty from 'js-quantities';
import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { clamp, isDecimalNumber } from '../utils/numberUtils';
import { defaultLogger, Logger } from '../utils/logging';
import { useEffectEvent } from '../utils/effectUtils';

const logger = new Logger(defaultLogger, 'MassInput', () => new Date().toISOString());

interface Props {
  value: string | null;
  enabledUnits: readonly MassUnit[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  min?: Record<MassUnit, number> | number;
  max?: Record<MassUnit, number> | number;
  step?: number;
  onChange: (val: string | null) => void;
}

export const massUnits = ['lbs', 'kg'] as const;
export type MassUnit = (typeof massUnits)[number];

function isMassUnit(val: unknown): val is MassUnit {
  return massUnits.includes(val as MassUnit);
}

const effectiveMin = (min: Props['min'], unit: MassUnit) => (typeof min === 'number' ? min : (min?.[unit] ?? 0));
const effectiveMax = (max: Props['max'], unit: MassUnit) => (typeof max === 'number' ? max : (max?.[unit] ?? Infinity));

const normalizeScalar = (scalar: number | null | undefined, unit: MassUnit, min: Props['min'], max: Props['max']) =>
  scalar == null || Number.isNaN(scalar) ? undefined : clamp(scalar, effectiveMin(min, unit), effectiveMax(max, unit));

const serializeValue = (scalar: number | undefined, unit: MassUnit) => (scalar == null ? null : `${scalar} ${unit}`);

export const MassInput = forwardRef(function MassInput(
  { value, enabledUnits, label, required, disabled, error, min, max, step, onChange: handleChange }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  if (enabledUnits.length === 0) {
    throw new Error('Empty enabledUnits. At least one unit must be enabled.');
  }

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<MassUnit>(enabledUnits[0]);
  const { t } = useTranslation('common');

  const setState = useCallback((sclr: typeof scalar, u: typeof unit) => {
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent((val: string | null, enbldUnts: readonly MassUnit[]) => {
    if (!val) {
      // Since serializeValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      const defaultUnit = enbldUnts.includes(unit) ? unit : enbldUnts[0];
      return setState(undefined, defaultUnit);
    }

    const quantity = Qty.parse(val);
    if (!quantity) {
      logger.error("Couldn't parse value", val);
      return setState(undefined, enbldUnts[0]);
    }

    const qtyUnit = quantity.units();
    if (!isMassUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Mass unit', qtyUnit);
      return setState(undefined, enbldUnts[0]);
    }

    if (!enbldUnts.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enbldUnts);
      return setState(undefined, enbldUnts[0]);
    }

    return setState(quantity.scalar, qtyUnit);
  });

  useEffect(() => {
    syncState(value, enabledUnits);
  }, [syncState, value, enabledUnits]);

  const handleUnitChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isMassUnit(event.target.value) && enabledUnits.includes(event.target.value) ? event.target.value : enabledUnits[0];
      const newScalar = normalizeScalar(scalar, newUnit, min, max);
      setState(newScalar, newUnit);
      handleChange(serializeValue(newScalar, newUnit));
    },
    [enabledUnits, handleChange, max, min, scalar, setState],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(serializeValue(newValue, unit));
      } else {
        setScalar(undefined);
        handleChange(serializeValue(undefined, unit));
      }
    },
    [handleChange, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar != null) {
      const { intValue } = isDecimalNumber(scalar);
      const newScalar = normalizeScalar(intValue, unit, min, max);
      setScalar(newScalar);
      handleChange(serializeValue(newScalar, unit));
    }
  }, [handleChange, max, min, scalar, unit]);

  return (
    <UnitInput<MassUnit>
      ref={ref}
      supportedUnits={enabledUnits}
      onScalarChange={handleScalarChange}
      onUnitChange={handleUnitChange}
      fieldLabel={label}
      scalar={scalar}
      unit={unit}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      error={error}
      renderMenuValue={(v) => t(`unit.mass.short.${v}`)}
      renderAdornmentValue={(v) => t(`unit.mass.short.${v}`)}
      min={effectiveMin(min, unit)}
      max={effectiveMax(max, unit)}
      step={step}
    />
  );
});
